import React, { FC } from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import {responseType} from '@helpers/guarantee.helper'

import RegisterResponseTemplate from '../register-response-template/RegisterResponseTemplate.component';
import { PageType } from '@constants/pageType.constants';

interface IRegisterIncomplete {
  email: string,
  linkPath: string,
}

const RegisterIncomplete: FC<IRegisterIncomplete> = ({email,linkPath}) => {
  const intl = useIntl();

  return (
    <RegisterResponseTemplate
      header={intl.formatMessage({ id: 'guarantee__responseHeader' })}
      descriptionPart1={intl.formatMessage({ id: 'guarantee__responseDescriptionPartialSuccess' })}
      email={email}
      isLinkEnable
      linkText={intl.formatMessage({ id: 'guarantee__responseLink' })}
      linkPath={linkPath}
      buttonText={intl.formatMessage({ id: 'guarantee__responseButton' })}
      result={responseType.PartialSuccess}
      buttonTargetPage={PageType.START}
    />
  );
};

export default RegisterIncomplete;
