import React, { FC } from 'react';

import { Segment } from '@components/segment/Segment';
import RegisterIncomplete from '@components/form-response/register-incomplete/RegisterIncomplete.component';
import { GatsbySeo } from 'gatsby-plugin-next-seo/lib';
import { useIntl } from 'gatsby-plugin-intl';

interface IRegistrationIncomplete {
  location: {
    state?: {
      email: string;
      link: string;
    }
  }
}

const RegistrationIncompleteTemplate: FC<IRegistrationIncomplete> = (props) => {
  const intl = useIntl();

  return (
    <>
      <GatsbySeo
        noindex={true}
        nofollow={true}
        title={`Avionaut - ${intl.formatMessage({ id: 'metaTitle__registrationIncomplete' })}`}
      />
      <Segment>
        <RegisterIncomplete
          email={props.location.state ? props.location.state.email : ''}
          linkPath={props.location.state ? props.location.state.link : ''}
        />
      </Segment>
    </>
  );
};

export default RegistrationIncompleteTemplate;
